// extracted by mini-css-extract-plugin
export var cls1 = "index-module--cls1--5NOiZ";
export var cls2 = "index-module--cls2--m-561";
export var cls3 = "index-module--cls3--ZSZ5n";
export var cls4 = "index-module--cls4--pVToX";
export var gif = "index-module--gif--I1jqr";
export var item = "index-module--item--Mm4pm";
export var map = "index-module--map--k40rf";
export var paragraphContainer = "index-module--paragraphContainer--kR7Zb";
export var socialPeace = "index-module--socialPeace--RowOg";
export var videoContainer = "index-module--videoContainer--vYaB9";