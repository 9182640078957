import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Contact from "../components/Contact";

let IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Contact Us" />
    <Contact/>
  </Layout>
);


export default IndexPage;
